import React from "react";
import { Box, CardMedia, CircularProgress, Container, Stack, Typography } from "@mui/material";
import OpenAPP from "../../components/OpenApp";
import ETHRestaking from "../../components/ETHRestaking";
import BTCRestaking from "../../components/BTCRestaking";
import SocialLinks from "../../components/SocialLinks";

import VideoStart from "./../../assets/videos/video-start.mp4";
import StartsCountdown from "../../components/StartsCountdown";
import { useTVL } from "../../service/useTVL";
import { formatNumber } from "../../utils/functions";
import { useTotalSupplyInUSD } from "../../service/amphorETH";

const SectionStart = () => {
    const { data: tvlLrtSummary, isLoading: isSlrtLoading } = useTVL("slrtVault", 1);
    const { data: tvlSummary, isLoading: isSmaLoading } = useTVL("smaVault", 1);
    const { data: supplyTVL, isLoading: isSupplyLoading } = useTotalSupplyInUSD();

    const isTVLLoading = isSmaLoading || isSlrtLoading;
    const isTotalTVLLoading = isSmaLoading || isSlrtLoading || isSupplyLoading;

    const supplyTVLUSDC = supplyTVL || 0;
    const slrtTVLUsdc = tvlLrtSummary?.slrtVault?.valueInUSDC || 0;
    const smaTVLUsdc = tvlSummary?.smaVault?.valueInUSDC || 0;
    const combinedTVL = smaTVLUsdc + slrtTVLUsdc + supplyTVLUSDC;
    const yieldOptimizerTVL = smaTVLUsdc + slrtTVLUsdc;

    return (
        <Box className="container-section vh100">
            {/* Background video */}
            <CardMedia
                component="video"
                sx={{ width: "100%", height: "100%", objectFit: "cover" }}
                src={VideoStart}
                autoPlay
                controls={false}
                loop
                muted
                playsInline
            />

            {/* Notification text */}
            <Container
                sx={{
                    position: "absolute",
                    top: { xs: "100px", sm: "130px" }, // Adjust top spacing for mobile
                    left: "50%",
                    transform: "translateX(-50%)",
                    zIndex: 2,
                    background: "transparent",
                    textAlign: "center",
                    px: { xs: 2, sm: 0 }, // Add padding on mobile
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        color: "#FF9800",
                        fontWeight: "bold",
                        fontSize: { xs: "1rem", sm: "1.25rem" }, // Responsive font size
                    }}
                >
                    Amphor is now part of InceptionLRT protocol.
                </Typography>
                <Typography
                    variant="subtitle1"
                    sx={{
                        color: "#FFFFFF",
                        fontSize: { xs: "0.875rem", sm: "1rem" }, // Responsive font size
                    }}
                >
                    Please visit{" "}
                    <a href="https://www.inceptionLRT.com" style={{ color: "#FF9800", textDecoration: "underline" }}>
                        www.inceptionLRT.com
                    </a>{" "}
                    for more.
                </Typography>
            </Container>

            {/* Main content */}
            <Container
                className="container-in margin absolute bottom"
                sx={{ zIndex: 1, py: { xs: 2, sm: 6, lg: 8 } }}
            >
                <Stack
                    direction={{ xs: "column", md: "column", lg: "row" }}
                    justifyContent={{ xs: "center", md: "space-between" }}
                    alignItems={{ xs: "center" }}
                    gap={2}
                >
                    <Stack
                        direction={{ xs: "column", md: "row" }}
                        justifyContent={{ xs: "center", md: "space-between" }}
                        alignItems={{ xs: "center", md: "flex-end" }}
                    >
                        <Box textAlign={{ xs: "center", md: "left" }}>
                            {/*<Typography variant="h4" sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }}>*/}
                            {/*    <Box component="span" color="primary.main">*/}
                            {/*        Shared security*/}
                            {/*    </Box>{" "}*/}
                            {/*    provider*/}
                            {/*</Typography>*/}
                            {/*<Typography*/}
                            {/*    component="div"*/}
                            {/*    variant="h1"*/}
                            {/*    sx={{*/}
                            {/*        fontSize: { xs: "2.5rem", sm: "4rem", md: "5rem" },*/}
                            {/*        fontWeight: 300,*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    Built to Last*/}
                            {/*</Typography>*/}
                            {/*<Stack direction={{ xs: "column", md: "row" }} spacing={1} sx={{ my: 1 }}>*/}
                            {/*    <ETHRestaking />*/}
                            {/*    <BTCRestaking />*/}
                            {/*    <OpenAPP text="Yield Optimizer vaults" />*/}
                            {/*</Stack>*/}
                            <SocialLinks sx={{ mt: 1.5 }} />
                        </Box>
                    </Stack>
                    <Stack direction="column" justifyContent="center" gap={1}>
                        <Stack direction={{ xs: "column", md: "row" }} justifyContent="center" gap={1}>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="space-between"
                                alignItems="center"
                                borderRadius="0.5rem"
                                border="1px solid"
                                sx={{
                                    borderColor: (theme) => theme.palette.primary.main,
                                    padding: { xs: "0.75rem 1rem", md: "1rem 1.5rem" }, // Adjust padding for mobile
                                    background: "linear-gradient(354.51deg, rgba(207, 124, 1, 0.11) 4.71%, rgba(0, 0, 0, 0.55) 96.01%)",
                                    boxShadow: "0px 0.25rem 1.25rem 0px #CF7C0133",
                                }}
                            >
                                <Typography variant="h5" textAlign="center" sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}>
                                    Amphor LRTs deposited
                                </Typography>
                                {isSupplyLoading ? (
                                    <CircularProgress size="24px" />
                                ) : (
                                    <Typography variant="h4" sx={{ fontSize: { xs: "1.25rem", sm: "1.5rem" } }}>
                                        ${formatNumber(supplyTVLUSDC, 0)}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="space-between"
                                alignItems="center"
                                borderRadius="0.5rem"
                                border="1px solid"
                                sx={{
                                    borderColor: (theme) => theme.palette.primary.main,
                                    padding: { xs: "0.75rem 1rem", md: "1rem 1.5rem" },
                                    background: "linear-gradient(354.51deg, rgba(207, 124, 1, 0.11) 4.71%, rgba(0, 0, 0, 0.55) 96.01%)",
                                    boxShadow: "0px 0.25rem 1.25rem 0px #CF7C0133",
                                }}
                            >
                                <Typography variant="h5" textAlign="center" sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}>
                                    Yield Optimizer Vaults
                                </Typography>
                                {isTVLLoading ? (
                                    <CircularProgress size="24px" />
                                ) : (
                                    <Typography variant="h4" sx={{ fontSize: { xs: "1.25rem", sm: "1.5rem" } }}>
                                        ${formatNumber(yieldOptimizerTVL, 0)}
                                    </Typography>
                                )}
                            </Box>
                        </Stack>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="0.5rem"
                            border="1px solid"
                            gap={0.5}
                            sx={{
                                borderColor: (theme) => theme.palette.primary.main,
                                padding: { xs: "0.75rem", md: "1rem" }, // Adjust padding for mobile
                                background: "linear-gradient(354.51deg, rgba(207, 124, 1, 0.11) 4.71%, rgba(0, 0, 0, 0.55) 96.01%)",
                                boxShadow: "0px 0.25rem 1.25rem 0px #CF7C0133",
                            }}
                        >
                            <Typography variant="h5" sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}>
                                Combined TVL:
                            </Typography>
                            {isTotalTVLLoading ? (
                                <CircularProgress size="24px" />
                            ) : (
                                <Typography variant="h4" sx={{ fontSize: { xs: "1.25rem", sm: "1.5rem" } }}>
                                    ${formatNumber(combinedTVL, 0)}
                                </Typography>
                            )}
                        </Box>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
};

export default SectionStart;

import React from "react";
import { Link, Stack, styled, SvgIcon, SxProps, Theme } from "@mui/material";
import {
  SiMedium,
  SiGitbook,
  SiGithub,
  SiX,
  SiDiscord,
  SiTelegram
} from "react-icons/si";
import {
  DEFI_LLAMA_URL,
  DISCORD_URL,
  GITBOOK_URL,
  GITHUB_URL,
  MEDIUM_URL,
  TELEGRAM_URL,
  TWITTER_URL
} from "../../constants/urls";
import {ReactComponent as IconDefiLlama} from "./../../assets/images/icon-defillama.svg";

interface Props {
  sx?: SxProps<Theme>;
}

const ButtonLink = styled(Link)(({ theme }) => ({
  fontSize: "1.25rem"
}));

const SocialLinks = ({ sx = [] }: Props) => {
  return (
    <Stack
      direction="row"
      justifyContent={{ xs: "center", sm: "flex-start" }}
      spacing={1.5}
      sx={sx}
    >
      {MEDIUM_URL ? (
        <ButtonLink href={MEDIUM_URL} target="_blank" title="Medium">
          <SiMedium />
        </ButtonLink>
      ) : (
        ""
      )}
      {GITBOOK_URL ? (
        <ButtonLink href={GITBOOK_URL} target="_blank" title="GitBook">
          <SiGitbook />
        </ButtonLink>
      ) : (
        ""
      )}
      {GITHUB_URL ? (
        <ButtonLink href={GITHUB_URL} target="_blank" title="GitHub">
          <SiGithub />
        </ButtonLink>
      ) : (
        ""
      )}
      {TWITTER_URL ? (
        <ButtonLink href={TWITTER_URL} target="_blank" title="Twitter">
          <SiX />
        </ButtonLink>
      ) : (
        ""
      )}
      {DISCORD_URL ? (
        <ButtonLink href={DISCORD_URL} target="_blank" title="Discord">
          <SiDiscord />
        </ButtonLink>
      ) : (
        ""
      )}
      {TELEGRAM_URL ? (
        <ButtonLink href={TELEGRAM_URL} target="_blank" title="Telegram">
          <SiTelegram />
        </ButtonLink>
      ) : (
        ""
      )}
      {DEFI_LLAMA_URL ? (
        <ButtonLink href={DEFI_LLAMA_URL} target="_blank" title="DefiLlama">
          <SvgIcon component={IconDefiLlama} sx={{height: '20px'}} inheritViewBox />
        </ButtonLink>
      ) : (
        ""
      )}
    </Stack>
  );
};

export default SocialLinks;
